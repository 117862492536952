@font-face {
	font-family: 'MuseoSansCyrl';
	font-weight: 300;
	font-style: normal;
	src: url('../fonts/museoSansCyrl-300.woff2') format('woff2'),
		url('../fonts/museoSansCyrl-300.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'MuseoSansCyrl';
	font-weight: 500;
	font-style: normal;
	src: url('../fonts/museoSansCyrl-500.woff2') format('woff2'),
		url('../fonts/museoSansCyrl-500.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'MuseoSansCyrl';
	font-weight: 700;
	font-style: normal;
	src: url('../fonts/museoSansCyrl-700.woff2') format('woff2'),
		url('../fonts/museoSansCyrl-700.woff') format('woff');
	font-display: swap;
}
